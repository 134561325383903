import React, { useState, useEffect, useContext } from 'react'
import { Link, navigate } from 'gatsby'
import Loader from 'react-loader-spinner'
import { useQueryParam, StringParam } from 'use-query-params'
import { Helmet } from 'react-helmet'
import { Global } from '@emotion/react'

import GlobalStyles from 'styles/GlobalStyles'
import { useAuth0 } from 'utils/react-auth0-spa'
import UserContext from 'context/user/UserContext'
import SubscriptionContext from 'context/subscription/SubscriptionContext'
import {
  StripeCheckoutRedirectContainer,
  StripeCheckoutContentContainer,
  StripeCheckoutRedirectDescription,
  StripeCheckoutRedirectButton,
  ShapeContainer,
} from 'components/StripeCheckout'
import Loading from 'components/Loading'
import LottieShape from 'components/LottieShape'
import AbstractPaymentAnimation from 'components/Animations/AbstractPaymentAnimation'
import {
  Header,
  TitleContainer,
  UserContainer,
  InputBox,
  LoginPrompt,
} from 'components/RedeemComponents'

const Redeem = () => {
  const SUBSCRIPTION_NAME = 'FluentWorlds Monthly'
  const SMART_TECHNOLOGIES_PATH = '/smart-technologies/redeem/'
  const SOURCE_SMART_TECHNOLOGIES = 'SMART TECHNOLOGIES'
  const SMART_TECHNOLOGIES_QUERY_PARAM = 'msisdn'
  const REDIRECT_QUERY_PARAM = 'redirect'
  const AUTH0_LOCK_SIGN_UP = 'signUp'
  const ACCOUNT_PATH = '/account'

  const [smartTechnologiesCodeInput, setSmartTechnologiesCodeInput] = useState(
    '',
  )
  const [auth0RedirectUri, setAuth0RedirectUri] = useState(
    process.env.GATSBY_WEBSITE_URL + SMART_TECHNOLOGIES_PATH,
  )
  const [redirectLoading, setRedirectLoading] = useState(false)
  const {
    subscriptions,
    subscriptionStateLoading,
    fetchAvailableSitePlans,
    createStripeSubscription,
  } = useContext(SubscriptionContext)
  const { isAuthenticated, loginWithRedirect, user, auth0Loading } = useAuth0()
  const {
    username,
    fetchUserFromFirestore,
    userStateLoading,
    fetchUserProductAccess,
    productAccess,
  } = useContext(UserContext)
  const [smartTechnologiesCodeQueryParam] = useQueryParam(
    SMART_TECHNOLOGIES_QUERY_PARAM,
    StringParam,
  )
  const [redirectQueryParam] = useQueryParam(REDIRECT_QUERY_PARAM, StringParam)
  // when user is authenticated, fetch the user from DB
  useEffect(() => {
    if (user && username === '') {
      fetchUserFromFirestore()
    }
    // eslint-disable-next-line
  }, [user])

  // once the user is authenticated, fetch their product access
  useEffect(() => {
    if (!auth0Loading && isAuthenticated) {
      fetchUserProductAccess()
    }

    // fetch the subscriptions
    if (isAuthenticated && subscriptions.length === 0) {
      fetchAvailableSitePlans()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0Loading, isAuthenticated])

  // Try to create a subscription if the user provides correct query parameter
  useEffect(() => {
    if (smartTechnologiesCodeQueryParam !== undefined) {
      setAuth0RedirectUri(
        auth0RedirectUri +
          '?' +
          SMART_TECHNOLOGIES_QUERY_PARAM +
          '=' +
          smartTechnologiesCodeQueryParam +
          '&' +
          REDIRECT_QUERY_PARAM +
          '=true',
      )
    }

    // if we are going to attempt to redirect the user then keep use redirectLoading to keep the
    // input form from showing while calling async functions
    if (redirectQueryParam !== undefined && redirectQueryParam === 'true') {
      setRedirectLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // if we've retrieved courseAccess and subscriptions from async functions then we can set
    // redirectLoading to true so we can call the async create subscription process.
    // we use the redirectLoading so we don't call function more than once and also put app in loading state.
    // And check that we should not set loading if using no MSISDN in smartTechnologiesCodeQueryParam
    if (
      subscriptions &&
      subscriptions.length > 0 &&
      productAccess &&
      !productAccess.fluentworlds &&
      !redirectLoading &&
      smartTechnologiesCodeQueryParam !== undefined
    ) {
      setRedirectLoading(true)
    }

    // Try and create a subscripton with smart technologies MSISDN as query parameter or
    // Navigate user to the account page if they have a access to fluentworlds product
    if (
      subscriptions &&
      subscriptions.length > 0 &&
      productAccess &&
      !productAccess.fluentworlds &&
      isAuthenticated &&
      !subscriptionStateLoading &&
      smartTechnologiesCodeQueryParam !== undefined
    ) {
      submitSmartTechnologiesCoupon(smartTechnologiesCodeQueryParam)
    } else if (productAccess && productAccess.fluentworlds) {
      navigate(ACCOUNT_PATH)
    }
    // eslint-disable-next-line
  }, [productAccess, subscriptions])

  const handleInputChange = (e) => {
    setSmartTechnologiesCodeInput(e.currentTarget.value)
  }

  const submitSmartTechnologiesCoupon = async (smartTechnologiesCodeIn) => {
    if (
      smartTechnologiesCodeIn === undefined ||
      smartTechnologiesCodeIn === ''
    ) {
      window.alert(
        'The Smart Technologies MSISDN provided was invalid, please try entering your MSISDN below',
      )
      return
    }

    // if creating the subscription using query param was unsucessful then show form for manual input
    const subscriptionCreated = await createStripeSubscription(
      SUBSCRIPTION_NAME,
      null,
      SOURCE_SMART_TECHNOLOGIES,
      smartTechnologiesCodeIn,
    )

    if (!subscriptionCreated) {
      // remove the loading state set when using the redirect
      setRedirectLoading(false)
    }
  }

  const mainContentLoading =
    auth0Loading ||
    subscriptionStateLoading ||
    userStateLoading ||
    redirectLoading ||
    (productAccess && productAccess.fluentworlds)

  return (
    <>
      <Helmet>
        <title>FluentWorlds</title>
      </Helmet>
      <Header>
        <Global styles={GlobalStyles} />
        <TitleContainer>Smart Technologies</TitleContainer>
        {userStateLoading ? (
          <Loader type="ThreeDots" color="#4A90E2" height={50} width={50} />
        ) : (
          isAuthenticated && (
            <UserContainer>{`Hello ${username}`}</UserContainer>
          )
        )}
      </Header>
      {mainContentLoading ? (
        <Loading />
      ) : isAuthenticated ? (
        <>
          <StripeCheckoutRedirectContainer>
            <ShapeContainer>
              <LottieShape
                size={500}
                animationData={AbstractPaymentAnimation}
              />
            </ShapeContainer>
            <StripeCheckoutContentContainer>
              <StripeCheckoutRedirectDescription>
                {'Please enter your MSISDN below:'}
              </StripeCheckoutRedirectDescription>
              <InputBox onChange={handleInputChange} />
              <StripeCheckoutRedirectButton
                onClick={() =>
                  submitSmartTechnologiesCoupon(
                    smartTechnologiesCodeInput.trim(),
                  )
                }
              >
                {'SUBMIT MSISDN'}
              </StripeCheckoutRedirectButton>
              <Link
                css={`
                  width: 300px;
                  margin-top: 3em;
                `}
                to="/account"
              >
                <StripeCheckoutRedirectButton>
                  {'GO TO ACCOUNT PAGE'}
                </StripeCheckoutRedirectButton>
              </Link>
            </StripeCheckoutContentContainer>
          </StripeCheckoutRedirectContainer>
        </>
      ) : (
        <LoginPrompt>
          {'Please create an account to redeem your MSISDN'}
          <StripeCheckoutRedirectButton
            css={'margin-top: 2rem;'}
            onClick={() =>
              loginWithRedirect({
                redirect_uri: auth0RedirectUri,
                mode: AUTH0_LOCK_SIGN_UP,
              })
            }
          >
            {'SIGN-UP'}
          </StripeCheckoutRedirectButton>
        </LoginPrompt>
      )}
    </>
  )
}

export default Redeem
